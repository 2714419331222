.venue-detail {
    padding: 1rem;
    background: #f9f9f9;
    max-width: 800px;
    margin: 0 auto;
    animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.venue-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.claim-button, .back-button {
    background-color: #DCA123;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    margin: 1rem 0;
    border-radius: 5px;
    display: inline-block;
    transition: background-color 0.3s ease;
}

.claim-button:hover, .back-button:hover {
    background-color: #c08d1b;
}

.back-button {
    float: left;
    margin-left: 50px;
    background-color: #DCA123;
}

.back-button:hover {
    background-color: #DCA123;
}

.card {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 1rem;
    margin-bottom: 1rem;
    animation: slideInUp 0.5s ease-in;
}

.card p {
    color: black;
}

@keyframes slideInUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

.info-card {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    background-color: #f1f1f1;
}

.info-card i {
    margin-right: 0.5rem;
    color: #DCA123;
}

@media (min-width: 600px) {
    .venue-detail {
        padding: 2rem;
    }

    .claim-button, .back-button {
        padding: 0.75rem 1.5rem;
    }
}

@media (min-width: 900px) {
    .venue-detail {
        padding: 2rem;
    }

    .venue-image {
        width: 75%;
        height: auto;
    }
}
