.friends-tab {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.friend-code-input,
.add-friend-button,
.show-friend-code-button,
.username-input,
.email-input {
    padding: 0.75rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 0.5rem;
}

.add-friend-button,
.show-friend-code-button {
    background: #DCA123;
    color: #fff;
    cursor: pointer;
}

.add-friend-button:hover,
.show-friend-code-button:hover {
    background: #DCA12369;
}

.friend-code-modal {
    padding: 1rem;
    background: #f9f9f9;
    border-radius: 10px;
    border: 1px solid #ccc;
    margin-top: 0.5rem;
}

.friends-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.friend-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.friend-card p {
    color: black;
    width: 500px;
}

.remove-friend-button {
    background: #ff4d4d;
    color: #fff;
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
}

.remove-friend-button:hover {
    background: #e60000;
}
