.feature-block {
    flex: 1 1 300px;
    margin: 10px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.feature-block:hover {
    transform: translateY(-10px);
}

.feature-icon {
    font-size: 4em;
    margin-bottom: 10px;
    color: #DCA123;
}

.feature-block h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.feature-block p {
    font-size: 1em;
    color: #666;
}
