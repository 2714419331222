.testimonial-card {
    flex: 1 1 300px;
    margin: 10px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.testimonial-card p {
    font-size: 1em;
    color: #666;
    margin-bottom: 10px;
}

.testimonial-card h4 {
    font-size: 1.2em;
    margin-bottom: 5px;
}

.testimonial-card h5 {
    font-size: 1em;
    color: #999;
}
