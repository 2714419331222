.reviews {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.review-card {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 1rem;
    animation: slideInUp 0.5s ease-in;
}

@keyframes slideInUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

.review-author {
    font-style: italic;
    color: #666;
}

.stars {
    display: flex;
    gap: 0.25rem;
}

.fa-star {
    color: #ccc;
    cursor: pointer;
}

.fa-star.checked {
    color: #ff9800;
}

.load-more-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    margin-top: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.load-more-button:hover {
    background-color: #0056b3;
}

.review-form {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    animation: slideInUp 0.5s ease-in;
}

.review-form input, .review-form textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.review-form button {
    background-color: #DCA123;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.review-form button:hover {
    background-color: #c08d1b;
}
