.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f0f0f0;
}

.login-form {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.login-form label {
    display: block;
    font-size: 1em;
    margin-bottom: 10px;
    color: #333;
}

.input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.input-field:focus {
    border-color: #DCA123;
}

.login-button {
    width: 100%;
    padding: 10px;
    background: #DCA123;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.login-button:hover {
    background: #DCA12369;
}

.link {
    color: #DCA123;
    text-decoration: none;
    transition: color 0.3s ease;
}

.link:hover {
    color: #DCA12369;
}

.error-message {
    color: #ff0000;
    font-size: 0.9em;
}

.remember-me {
    display: flex;
    align-items: center;
    font-size: 0.9em;
    color: #333;
    margin-bottom: 20px;
}

.remember-me input {
    margin-right: 10px;
}

.remember-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.remember-options label {
    font-size: 0.9em;
    color: #333;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.remember-options input[type="radio"] {
    margin-right: 5px;
}

.remember-me input[type="checkbox"],
.remember-options input[type="radio"] {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    accent-color: #DCA123;
}
