.maintenance-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #515357;
}

.maintenance-logo {
    width: 150px;
    margin-bottom: 20px;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color:white;
}

p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color:white;
}

.icon {
    font-size: 5rem;
    color: #DCA123;
}
