.register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #f0f0f0;
}

.login-form {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.login-form label {
    display: block;
    font-size: 1em;
    margin-bottom: 10px;
    color: #333;
}

.input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.input-field:focus {
    border-color: #DCA123;
}

.login-button {
    width: 100%;
    padding: 10px;
    background: #DCA123;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.login-button:hover {
    background: #DCA12369;
}

.link {
    color: #DCA123;
    text-decoration: none;
    transition: color 0.3s ease;
}

.link:hover {
    color: #DCA12369;
}

.error-message {
    color: #ff0000;
    font-size: 0.9em;
}

.error-container {
    background: #ffe6e6;
    border: 1px solid #ff0000;
    padding: 10px;
    border-radius: 5px;
}

.role-selection {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.role-button {
    width: 48%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #f0f0f0;
    cursor: pointer;
    transition: background 0.3s ease, border-color 0.3s ease;
}

.role-button.active {
    background: #DCA123;
    color: #fff;
    border-color: #007bff;
}

.role-button:hover {
    background: #e0e0e0;
}
