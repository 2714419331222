.preferences-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f0f0f0;
}

.preferences-form {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

.preferences-form label {
    font-size: 1em;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #333;
}

.preferences-form input[type="checkbox"] {
    margin-right: 10px;
}

.save-button {
    width: 100%;
    padding: 10px;
    background: #DCA123;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 20px;
}

.save-button:hover {
    background: #0056b3;
}
