.filters-wrapper {
    margin-bottom: 1rem;
}

.toggle-filters-button {
    padding: 0.75rem 1.5rem;
    background: #DCA123;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top:25px;
}

.toggle-filters-button:hover {
    background: #DCA12369;
}

.filters {
    padding: 1rem;
    background: #f9f9f9;
    border-radius: 10px;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.location-input {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.modern-input {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
}

.radius-slider {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.radius-slider label {
    font-size: 1rem;
}

.modern-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.modern-slider:hover {
    opacity: 1;
}

.modern-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #DCA123;
    cursor: pointer;
}

.modern-slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #DCA123;
    cursor: pointer;
}

.features-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1rem;
}

.feature-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.feature-icon {
    width: 40px;
    height: 40px;
    border-radius: 9px;
}

.feature-icon.checked {
    border: 2px solid #000;
    border-radius: 50%;
}

.filter-button {
    padding: 0.75rem 1.5rem;
    background: #DCA123;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    align-self: center;
}

.filter-button:hover {
    background: #DCA12369;
}

.animated {
    animation-duration: 0.5s;
    animation-fill-mode: both;
}

.fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.suggestions-list {
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    max-height: 150px;
    overflow-y: auto;
    margin-top: 0.5rem;
}

.suggestions-list li {
    padding: 0.5rem;
    cursor: pointer;
}

.suggestions-list li:hover {
    background: #f0f0f0;
}
