.listings-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.venues-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
}

@media (min-width: 600px) {
    .venues-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 900px) {
    .venues-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

.venue-card {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
}

.venue-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.image-container {
    position: relative;
}

.venue-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.distance-card {
    position: absolute;
    bottom: 30px;
    right: 10px;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
}

.favourite-button, .drinking-at-button {
    position: absolute;
    top: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #ccc;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.favourite-button.favourite, .drinking-at-button.drinking {
    color: #DCA123;
}

.favourite-button {
    left: 10px;
}

.drinking-at-button {
    left: 50px;
}

.venue-info {
    padding: 1rem;
}

.venue-info h3 {
    margin: 0;
    font-size: 1.25rem;
    color: #333;
}

.venue-info p {
    margin: 0.5rem 0;
    color: #666;
}

.features {
    display: flex;
    overflow-x: auto;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
    position: relative;
}

.features::-webkit-scrollbar {
    display: none;
}

.feature-icon {
    width: 24px;
    height: 24px;
    object-fit: cover;
}

.venue-rating {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.stars {
    display: flex;
}

.fa-star {
    color: #ccc;
}

.fa-star.checked {
    color: #ff9800;
}

.venue-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.venue-actions button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    color: #DCA123;
}

.venue-actions button.favourite {
    color: red;
}

.load-more {
    padding: 0.75rem 1.5rem;
    background: #DCA123;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin: 2rem auto 0;
    display: block;
}

.load-more:hover {
    background: #DCA123;
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.pagination-button {
    padding: 0.5rem 1rem;
    background: #dcba89;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.pagination-button:hover {
    background: #DCA123;
}

.pagination-button.active {
    background: #DCA123;
}

.pagination-page {
    padding: 0.5rem 1rem;
    background: #DCA123;
    color: #fff;
    border-radius: 5px;
}

.friends-drinking-here {
    margin-top: 16px;
    background: #dcdddd;
    padding: 5px;
    border-radius: 12px;
}

.friends-drinking-here > span {
    margin-left: 5px;
}