.loading-area {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    display: flex;
    justify-content: space-around;
    width: 80px;
    margin-top: 150px;
    margin-bottom: 150px;
}

.dot {
    width: 16px;
    height: 16px;
    background-color: #DCA123;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out both;
}

.dot:nth-child(1) { animation-delay: -0.32s; }
.dot:nth-child(2) { animation-delay: -0.16s; }
.dot:nth-child(3) { animation-delay: 0s; }

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
