.cta-section {
    text-align: center;
    height:500px;
    width: 100%;
    color: #fff;
    border: 1px solid black;
    display: flex;
}

.cta-section h1 {
    margin-top:100px;
}

.cta-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.cta-section p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.cta-section button {
    background: #fff;
    color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.cta-section button:hover {
    background: #f1f1f1;
}

.downloadImages {
    display: flex;
    gap: 50px;
    align-content: center;
    text-align: center;
    margin: auto;
    place-content: center;
    padding: 50px;
    background: #fff;
    width: 500px;
    border-radius: 25px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Adds the shadow */
}


.downloadImage {
    width: 100px;
}

.largePhoneInverse {
    width: 675px;
    position: absolute;
    left: 50px;
}