.general-info {
    padding: 20px;
}

.general-info h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: black;
}

.general-info p {
    font-size: 1em;
    margin-bottom: 10px;
    color: #333;
}

.general-info hr {
    margin: 20px 0;
}
