.favourites {
    padding: 2rem;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.favourites h1 {
    margin-bottom: 2rem;
}
