.about-container {
    width: 100%;
    background: #f9f9f9;
    border-radius: 10px;
    color: #333;
}

.flex-container {
    width: 100%;
    min-height: 500px;
    height: 500px;
    display: flex;
}


.flex-container-small {
    width: 100%;
    height: 200px;
    display: flex;
}

.about-header {
    text-align: center;
    margin-bottom: 40px;
    color: black;
}

.about-header h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: white;
}

.about-header p {
    font-size: 1.2em;
    line-height: 1.6;
    color: white;
}

.mission-vision {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
}

.mission-vision .mission, .mission-vision .vision {
    flex: 1;
    min-width: 300px;
    margin: 10px;
    padding: 20px;
}

.mission h2, .vision h2, .history h2 {
    font-size: 1.8em;
    margin-bottom: 30px;
    margin-top: 80px;
    color: black;
    text-align: center;
}

.mission p, .vision p, .history p{
    font-size: 1.1em;
    margin-bottom: 10px;
    margin-top: 20px;
    color: black;
    width: 50%;
    text-align: center;
    margin: auto;
}

.history {
    margin-bottom: 40px;
}

.history h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.history p {
    font-size: 1.1em;
    line-height: 1.6;
}

.services {
}

.services h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.services ul {
    list-style-type: disc;
    padding-left: 20px;
}

.services ul li {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 10px;
}

.suggestions {
    margin-bottom: 40px;
    text-align: center;
}

.suggestions h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.suggestions p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.pubImage {
    width: 500px;
    position: absolute;
    right: 100px;
}

.section-left {
    width: 75%;
    background-color: #33363e;
    height:100%;
    align-content: center;
}

.section-right {
    width: 25%;
    background-color: #DCA123;
    height:100%;
}

.section-left-inverse {
    width: 25%;
    background-color: #33363e;
    height:100%;
    z-index: 99999999;
}

.section-right-inverse {
    width: 75%;
    background-color: #DCA123;
    height:100%;
}

.section-left-50 {
    width: 50%;
    background-color: #33363e;
    height:100%;
    align-content: center;
}

.section-right-50 {
    width: 50%;
    background-color: #DCA123;
    height:100%;
}