.claim {
    padding: 2rem;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.claim h1 {
    margin-bottom: 1rem;
}

.claim p {
    margin-bottom: 1rem;
}

.claim label {
    display: block;
    margin-bottom: 1rem;
}

.claim button {
    background-color: #DCA123;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
}
