.testimonial-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}

.testimonial-row {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    margin-bottom: 20px;
    animation: scrollTestimonials 15s linear infinite;
    transition: animation 0.5s;
}

.testimonial-row:hover {
    animation-play-state: paused;
}

.show-more-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.show-more-button:hover {
    background-color: #0056b3;
}

.testimonial-card {
    flex: 0 0 300px;
    margin: 10px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.testimonial-card p {
    font-size: 1em;
    color: #666;
    margin-bottom: 10px;
}

.testimonial-card h4 {
    font-size: 1.2em;
    margin-bottom: 5px;
}

.testimonial-card h5 {
    font-size: 1em;
    color: #999;
}

@keyframes scrollTestimonials {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
