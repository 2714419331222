@import url('./Core.css');

.flex {
    display: flex;
    width: 100%;
}

.section-left {
    width: 75%;
    background-color: #33363e;
    height:100%;
}

.section-right {
    width: 25%;
    background-color: #DCA123;
    height:100%;
}

.section-left-inverse {
    width: 25%;
    background-color: #33363e;
    height:100%;
    z-index: 99999999;
}

.section-right-inverse {
    width: 75%;
    background-color: #DCA123;
    height:100%;
}

.centered-box {
    text-align: left;
    padding: 50px;
    width: 100%;
}

.centered-box h1 {
    line-height: 50px;
    font-weight: 900;
}

.centered-box li {
    line-height: 50px;
    list-style: none; /* Remove default bullets */
    position: relative;
    color: white; /* Keep the text white */
    padding-left: 30px; /* Add space for the arrow */
}

.centered-box li::before {
    content: '→'; /* Unicode arrow */
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px; /* Adjust the size of the arrow */
    color: #DCA123; /* Set the arrow color */
}

.centered-box p {
    line-height: 30px;
    width: 60%;
}

.centered-box p.quote {
    font-style: italic;
}

.home-container {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
}

.home-header {
    position: relative;
    text-align: center;
    color: #fff;
    height: 700px;
    background: #dca123;
}

.features-section {
    max-width: 1200px;
    margin: 40px auto;
    text-align: center;
}

.features-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.features-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.testimonials-section {
    text-align: center;
    padding: 40px 0px;
    background: #33363e;
}

.testimonials-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.pricing-section {
    max-width: 1200px;
    margin: 40px auto;
    text-align: center;
    padding: 40px 20px;
    background: #f1f1f1;
}

.pricing-section h2 {
    font-size: 2em;
    margin-bottom: 20px;
}

.pricing-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.largePhone {
    position: absolute;
    top: 35px;
    width: 800px;
    right: 0;
}

.downloadButtons button {
    font-size: 25px;
    border-radius: 5px;
    background: #DCA123;
    color: white;
    padding: 10px;
    border: none;
    transition: all .5s;
}

.downloadButtons button:hover {
    font-size: 25px;
    border-radius: 5px;
    background: #e0b24f;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    transition: all .5s;
}

@media (max-width: 768px) {
    .pricing-card, .testimonial-card {
        min-width: calc(100% - 20px); /* Ensure cards take full width on small screens */
    }
}
