.account-settings {
    padding: 20px;
}

.account-settings h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: black;
}

.delete-button, .confirm-delete-button {
    padding: 10px 20px;
    background: #ff0000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.delete-button:hover, .confirm-delete-button:hover {
    background: #cc0000;
}

.delete-confirmation {
    margin-top: 20px;
}

.delete-confirmation label {
    font-size: 1em;
    color: #333;
}

.delete-confirmation input {
    margin-right: 10px;
}
